import { singleton } from 'tsyringe';
import { AuthenticationService } from '../../cross-cutting-concerns/authentication/AuthenticationService';
import {
  TermsConditionsClient,
  TermsConditionsClientAcceptOptions,
  TermsConditionsAcceptResponse,
} from './TermsConditionsClient';
import { CURRENT_TERMS_AND_CONDITIONS_GROUP } from './constants';
import { Optional } from 'lib/types/Optional';

export type TermsConditionsServiceAcceptOptions = TermsConditionsClientAcceptOptions;

@singleton()
export class TermsConditionsService {
  constructor(
    private termsConditionsClient: TermsConditionsClient,
    private authenticationService: AuthenticationService
  ) {}

  public acceptTermsAndCondition = async ({
    input,
  }: TermsConditionsServiceAcceptOptions): Promise<Optional<TermsConditionsAcceptResponse>> => {
    const { data } = await this.termsConditionsClient.acceptTermsAndCondition({ input });
    return data;
  };

  public hasAgreedToCurrentTerms = async (): Promise<boolean> => {
    const userGroups = await this.authenticationService.getUserGroups();

    return userGroups.includes(CURRENT_TERMS_AND_CONDITIONS_GROUP);
  };
}
