import { FetchResult, gql } from '@apollo/client';
import { inject, injectable } from 'tsyringe';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';
import {
  MutationAcceptTermsAndConditionArgs,
  ResponseAcceptTermsAndCondition,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface TermsConditionsAcceptResponse {
  acceptTermsAndCondition: ResponseAcceptTermsAndCondition;
}
export type TermsConditionsClientAcceptOptions = MutationAcceptTermsAndConditionArgs;

@injectable()
export class TermsConditionsClient {
  constructor(@inject('GraphQlClient') private client: GraphQlClient) {}

  public acceptTermsAndCondition = async ({
    input,
  }: TermsConditionsClientAcceptOptions): Promise<FetchResult<TermsConditionsAcceptResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation TermsAndConditionAccept($input: InputAcceptTermsAndCondition!) {
          acceptTermsAndCondition(input: $input) {
            data {
              acceptedAt
              customerId
              version
            }
          }
        }
      `,
      variables: {
        input,
      },
    });
}
