import { useSelector } from 'react-redux';
import { selectUserCountryCode } from '../../../../cross-cutting-concerns/authentication/state/authenticationSelectors';
import { CURRENT_CONTRACT_OBLIGATION_VERSION } from '../../constants';
import { StyledContractObligationContent } from './ContractObligationContent.styles';
import { contractObligationContentAustralia } from './country/australia/contractObligationContentAustralia';
import { contractObligationContentAustria } from './country/austria/contractObligationContentAustria';
import { contractObligationContentBelgium } from './country/belgium/contractObligationContentBelgium';
import { contractObligationContentCzechRepublic } from './country/czech-republic/contractObligationContentCzechRepublic';
import { contractObligationContentDenmark } from './country/denmark/contractObligationContentDenmark';
import { contractObligationContentFrance } from './country/france/contractObligationContentFrance';
import { contractObligationContentGermany } from './country/germany/contractObligationContentGermany';
import { contractObligationContentItaly } from './country/italy/contractObligationContentItaly';
import { contractObligationContentNetherlands } from './country/netherlands/contractObligationContentNetherlands';
import { contractObligationContentNorway } from './country/norway/contractObligationContentNorway';
import { contractObligationContentSweden } from './country/sweden/contractObligationContentSweden';
import { contractObligationContentSwitzerland } from './country/switzerland/contractObligationContentSwitzerland';
import { contractObligationContentUnitedKingdom } from './country/united-kingdom/contractObligationContentUnitedKingdom';
import { contractObligationContentUsa } from './country/usa/contractObligationContentUsa';
import { Translations } from 'app/cross-cutting-concerns/translations/Translations';
import { Country } from 'app/cross-cutting-concerns/translations/interfaces/Translations.types';

export const ContractObligationContent = (): JSX.Element => {
  const userCountryCode = useSelector(selectUserCountryCode);
  const country = Translations.getCountryByCountryCode(userCountryCode);

  // Use script at tools/contract-word-extractor to generate baseline markup from original .docx file
  // This will need so manual adaptions for correctly formatting headings, subheadings and adding spacers
  // (potentially more depending on the source file)
  const ContentByCountryMap: Record<Country, any> = {
    [Country.AUSTRALIA]: contractObligationContentAustralia,
    [Country.AUSTRIA]: contractObligationContentAustria,
    [Country.BELGIUM]: contractObligationContentBelgium,
    [Country.CZECH_REPUBLIC]: contractObligationContentCzechRepublic,
    [Country.DENMARK]: contractObligationContentDenmark,
    [Country.FRANCE]: contractObligationContentFrance,
    [Country.GERMANY]: contractObligationContentGermany,
    [Country.ITALY]: contractObligationContentItaly,
    [Country.NETHERLANDS]: contractObligationContentNetherlands,
    [Country.NORWAY]: contractObligationContentNorway,
    [Country.SWEDEN]: contractObligationContentSweden,
    [Country.SWITZERLAND]: contractObligationContentSwitzerland,
    [Country.UNITED_KINGDOM]: contractObligationContentUnitedKingdom,
    [Country.USA]: contractObligationContentUsa,
    [Country.SPAIN]: contractObligationContentUsa, // TODO: Add Spain contract obligation content
    [Country.JAPAN]: contractObligationContentUsa, // TODO: Add Japan contract obligation content
    [Country.PORTUGAL]: contractObligationContentUsa, // TODO: Add Portugal contract obligation content
    [Country.KOREA]: contractObligationContentUsa, // TODO: Add Portugal contract obligation content
  };

  const content = ContentByCountryMap[country];

  return (
    <StyledContractObligationContent>
      <div
        className="contract-obligation-content"
        dangerouslySetInnerHTML={{ __html: content[CURRENT_CONTRACT_OBLIGATION_VERSION] }}
      />
    </StyledContractObligationContent>
  );
};
