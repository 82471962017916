import styled from 'styled-components';

export const StyledContractObligationContent = styled.div`
  .contract-obligation-content__subheading,
  .contract-obligation-content__paragraph {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1em;
    column-gap: 1em;
  }

  .contract-obligation-content__heading {
    ${(props): string => props.theme.fontStyles.heading3};
    margin-bottom: 1em;
  }

  .contract-obligation-content__subheading {
    ${(props): string => props.theme.fontStyles.heading4};
  }

  .contract-obligation-content__paragraph {
    ${(props): string => props.theme.fontStyles.p1.news};
  }

  .contract-obligation-content__spacer {
    width: 26px;
  }

  .contract-obligation-content__text {
    flex-basis: auto;
  }
`;
