/* eslint-disable max-len */

import { ContractObligationVersions } from '../../../../interfaces/TermsAndConditions.types';

export const contractObligationContentFrance: Record<ContractObligationVersions, string> = {
  [ContractObligationVersions.V1]: `<div>
  <div class="contract-obligation-content__heading">
    <div class="contract-obligation-content__text">General Terms and Conditions Governing the Use of the Kärcher Equipment Management Customer Portal (Terms and Conditions)</div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__text">
    </div>
  </div>
  <div class="contract-obligation-content__subheading">
    <div class="contract-obligation-content__index">1.</div>
    <div class="contract-obligation-content__text">General</div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">1.1</div>
    <div class="contract-obligation-content__text">These General Terms and Conditions of Contract (hereinafter, "Terms
      and Conditions" or "T&C") of Kärcher SAS (5, avenue des Coquelicots Z.A. des Petits Carreaux, 94865 Bonneuil sur
      Marne Cedex) (hereinafter also, "Kärcher") apply only to entrepreneurs, legal persons under private or public law
      or special funds under public law within the meaning of Section 310 (1) German Civil Code (BGB) (hereinafter,
      "Customer").
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">1.2</div>
    <div class="contract-obligation-content__text">If you are registering for the first time on the Kärcher customer
      portal and accepting these T&C on behalf of a Customer, you hereby represent and warrant to Kärcher that you have
      the authority to bind the Customer to these T&C. If you do not have such authority, or if you or the Customer does
      not consent to being bound to these T&C, you must not accept these T&C and neither you nor the Customer may use
      the contractual services.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">1.3</div>
    <div class="contract-obligation-content__text">The Customer's general terms and conditions (if any) shall not
      apply.
    </div>
  </div>
  <div class="contract-obligation-content__subheading">
    <div class="contract-obligation-content__index">2.</div>
    <div class="contract-obligation-content__text">General information regarding the subject matter of the contract
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">2.1</div>
    <div class="contract-obligation-content__text">With its equipment management system, Kärcher offers its commercial
      Customers a web-based secure-access software application for easy fleet and machine management in the form of a
      customer portal.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">2.2</div>
    <div class="contract-obligation-content__text">The individual data and functions that can be called up by the
      Customer in the customer portal depend on the machine model used by the Customer and on whether and, if so, what
      hardware module for data transmission is installed in that model. An overview of the functions of the respective
      hardware module and of the data that can be called up is contained in the specifications regarding the hardware
      module or will be made available to you by Kärcher upon request.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">2.3</div>
    <div class="contract-obligation-content__text">The master data and basic functions of the customer portal
      (hereinafter, "Equipment Management") are available to the Customer even without a built-in hardware module for
      data transmission, both for Kärcher machines and for machines from other suppliers.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">2.4</div>
    <div class="contract-obligation-content__text">To be able to use so-called "near-real-time" data and additional
      functions via the customer portal, the respective machine of the Customer must be equipped, or retrofitted, with a
      hardware module for data transmission.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">2.5</div>
    <div class="contract-obligation-content__text">The Customer may enter its own additional customer-specific and
      machine-specific data into the customer portal, for example, the buildings to be cleaned, the cleaning times or
      other information about the use of the respective machine (hereinafter, "Portal Data").
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">2.6</div>
    <div class="contract-obligation-content__text">Kärcher shall grant the Customer access to, and enable the Customer
      to use, the customer portal.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">2.7</div>
    <div class="contract-obligation-content__text">Once the customer account has been set up by Kärcher, the Customer
      can access the customer portal via the web address https://em.kaercher.com/. Access via the respective current
      versions of the Google Chrome, Microsoft Edge, Mozilla Firefox and Apple Safari web browsers and the Microsoft
      Windows operating system is supported. Access via other web browsers and operating systems may be restricted.
      Access via mobile devices is not supported.
    </div>
  </div>
  <div class="contract-obligation-content__subheading">
    <div class="contract-obligation-content__index">3.</div>
    <div class="contract-obligation-content__text">Formation of contract</div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">3.1</div>
    <div class="contract-obligation-content__text">Upon request, Kärcher will provide the Customer with access data
      (e.g. user, initial pass word) and the link to the portal. The Customer's request can be made without observing
      any particular form, for example, by telephone or e-mail. The Customer can then use the access data provided by
      Kärcher to log into the portal, consenting to these T&C in the process. The contract regarding the use of the
      equipment management system between Kärcher and the Customer is formed upon login and acceptance of these T&C. The
      contact person indicated in the Customer's request is registered in the customer account as administrator and, as
      such, can create further access rights for employees of the Customer in the customer account.
    </div>
  </div>
  <div class="contract-obligation-content__subheading">
    <div class="contract-obligation-content__index">4.</div>
    <div class="contract-obligation-content__text">Maintenance and availability</div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">4.1</div>
    <div class="contract-obligation-content__text">The accessibility of the customer portal is monitored Monday to
      Friday, 9am to 4pm CET (hereinafter also "Service Hours"), except on public holidays in Germany
      (Baden-Württemberg). In the event of serious faults - i.e. when the customer portal can no longer be used, or when
      its use is significantly restricted - maintenance measures will, within Service Hours, be initiated without undue
      delay upon knowledge of the fault or notification by the Customer. Kärcher will notify the Customer of any such
      maintenance work promptly via the customer portal or by e-mail and carry out the work in the shortest possible
      time in accordance with the technical conditions.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">4.2</div>
    <div class="contract-obligation-content__text">The availability of the customer portal shall be 98% on an annual
      average, with the exception of maintenance periods.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">4.3</div>
    <div class="contract-obligation-content__text">The provider may suspend the provision of the services outside
      Service Hours in order to carry out maintenance work. Kärcher will endeavour to notify the Customer of the exact
      maintenance period five days in advance, via the customer portal or by e-mail. In the event that any unscheduled
      and/or unforeseeable maintenance work or resolution of faults becomes necessary at other times and it is not
      reasonably possible for Kärcher to comply with the five-day notice period, Kärcher will notify the Customer as
      early as possible of the time and duration of the service interruption. During planned regular maintenance work
      ("Scheduled Maintenance Work"), the Customer shall have no right to demand to be able to use the software
      application.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">4.4</div>
    <div class="contract-obligation-content__text">A fault to be remedied by Kärcher shall not be deemed to exist if
      there are problems with the data connection from the Customer and the Customer's users' devices to the Internet,
      for example, due to line failures or disruptions on the part of other providers or telecommunications companies,
      or if the system capacities provided are used in breach of the terms of this contract.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">4.5</div>
    <div class="contract-obligation-content__text">In the event of problems with the connection between the cloud-based
      software application and the Customer's machines, the data of the respective machine may happen to be unavailable
      in the customer portal. If Kärcher is not responsible for the problems with the connection, the service shall not
      be deemed unavailable for the purposes of this contract. Such problems with the connection for which Kärcher is
      not responsible include, in particular, atmospheric disturbances, topographical factors or obstacles such as
      bridges, tunnels or buildings.
    </div>
  </div>
  <div class="contract-obligation-content__subheading">
    <div class="contract-obligation-content__index">5.</div>
    <div class="contract-obligation-content__text">Right to use the software application</div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">5.1</div>
    <div class="contract-obligation-content__text">Kärcher hereby grants the Customer the non-exclusive,
      non-transferable and non-sublicensable right to use the software application during the term of the contract in
      accordance with the provisions of these T&C.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">5.2</div>
    <div class="contract-obligation-content__text">Information about the display of "near-real-time" data in the
      customer portal and further additional functions can be gathered from the specifications regarding the hardware
      module or will be made available by Kärcher upon request.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">5.3</div>
    <div class="contract-obligation-content__text">The Customer shall have no right to grant third parties permission to
      use the software, whether for payment or free of charge, during the term of the contract. Persons using the
      services free of charge and as intended for fleet and machine management purposes on behalf of the Customer or
      based on a right of access granted by the Customer - for example, administrative users and employees of the
      Customer - shall not be deemed third parties. The Customer is thus expressly prohibited from subletting the
      software.
    </div>
  </div>
  <div class="contract-obligation-content__subheading">
    <div class="contract-obligation-content__index">6.</div>
    <div class="contract-obligation-content__text">Rights in Machine Data and Portal Data</div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">6.1</div>
    <div class="contract-obligation-content__text">Alfred Kärcher SE & Co. KG ("AKW") shall be the owner of the machine
      data collected by the hardware module for data transmission and processed in the customer portal (hereinafter,
      "Machine Data"), as well as the sole owner of all rights in and to such Machine Data. This shall apply
      irrespective of who owns the machine whose data is being collected.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">6.2</div>
    <div class="contract-obligation-content__text">In addition, the Customer hereby grants AKW the non-exclusive and
      irrevocable right, unlimited in time and geographic scope, to use the Portal Data entered by the Customer in an
      anonymised form for internal purposes, in particular for market research and the improvement of products.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">6.3</div>
    <div class="contract-obligation-content__text">If the processed data constitutes trade secrets of the Customer, the
      Customer hereby grants AKW and Kärcher a permanent, irrevocable, nonexclusive and non-transferable right to use
      such data in an anonymised form for their own business purposes (in particular, for analyses and evaluations with
      a view to improving the services under this contract and also other products of Kärcher). AKW and Kärcher will
      treat such data as strictly confidential, in accordance with clause 15 of these T&C.
    </div>
  </div>
  <div class="contract-obligation-content__subheading">
    <div class="contract-obligation-content__index">7.</div>
    <div class="contract-obligation-content__text">Customer's obligations</div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">7.1</div>
    <div class="contract-obligation-content__text">The Customer shall notify Kärcher of any faults or errors of the
      customer portal within a reasonable period of time after their occurrence, using the contact form provided in the
      customer portal or by e-mail to the service e-mail address provided in the customer portal.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">7.2</div>
    <div class="contract-obligation-content__text">As part of its account information for the customer portal, the
      Customer shall indicate a contact person (administrator), who shall be available during normal business hours to
      answer any questions Kärcher may have with regard to the services under the contract and the contract itself. To
      the extent that the contact person is not available, the Customer shall indicate a replacement. The contact person
      and his or her replacement from time to time must be authorised to make decisions on behalf of the Customer with
      regard to the administration and use of the customer portal.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">7.3</div>
    <div class="contract-obligation-content__text">Customer portal access rights may only be granted by the Customer to
      employees and representatives authorised by it. The Customer shall be obliged to keep the access rights secret and
      not make them accessible to third parties. The Customer shall impose corresponding obligations on its employees
      and representatives. The Customer shall further be obliged to notify Kärcher without undue delay of any potential
      misuse of access rights and accounts and other security incidents in connection with the customer portal.
    </div>
  </div>
  <div class="contract-obligation-content__subheading">
    <div class="contract-obligation-content__index">8.</div>
    <div class="contract-obligation-content__text">Fee</div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">8.1</div>
    <div class="contract-obligation-content__text">The Customer may use the Equipment Management of the customer portal
      free of charge.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">8.2</div>
    <div class="contract-obligation-content__text">During the first five years following the provision of the hardware
      module, the use of the "near-real-time" data and of the additional functions in the customer portal that go beyond
      Equipment Management shall be deemed included in the price for the purchase of or, where applicable, the fee for
      the permission to use the hardware module for data transmission. Upon expiry of this five-year period, the right
      of use can be renewed subject to a fee.
    </div>
  </div>
  <div class="contract-obligation-content__subheading">
    <div class="contract-obligation-content__index">9.</div>
    <div class="contract-obligation-content__text">Modification of services</div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__text">Kärcher reserves the right to make adjustments to the services under
      the contract, to the extent necessary for technical reasons or due to a change in subcontractors. The modification
      of the services may not lead to any significant modification to the services or be otherwise unreasonable for the
      Customer. An extension of the scope of services by Kärcher shall be permitted at any time. However, Kärcher
      reserves the right to make an extended scope of services, such as new functions and features in the customer
      portal, only available to the Customer for use subject to an additional fee.
    </div>
  </div>
  <div class="contract-obligation-content__subheading">
    <div class="contract-obligation-content__index">10.</div>
    <div class="contract-obligation-content__text">Subcontractors</div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__text">Kärcher uses subcontractors to provide the services. Kärcher shall
      have absolute discretion as to the selection of subcontractors. However, Kärcher will select its subcontractors
      with due care, taking into account the Customer legitimate interests, to the extent known to Kärcher. The right to
      select subcontractors shall also include the right to replace subcontractors during the term of the contract,
      unless such a replacement would adversely affect the Customer's legitimate interests.
    </div>
  </div>
  <div class="contract-obligation-content__subheading">
    <div class="contract-obligation-content__index">11.</div>
    <div class="contract-obligation-content__text">Blocking the customer account in the event of an infringement of
      rights
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__text">Kärcher shall have the right to immediately block the customer
      account in the customer portal if there is reason to suspect that the collection or use of the data is unlawful or
      infringes third-party rights. In particular data processing activities performed in breach of the terms of the
      General Data Protection Regulation (GDPR) constitute an infringement of third-party rights (see clause 14 below in
      this respect). Reason to suspect that data has been collected or used unlawfully and/or that an infringement of
      rights has occurred shall especially be deemed to exist if Kärcher is so notified by a court, a public authority
      and/or another third party. Kärcher shall inform the Customer thereof without undue delay, to the extent permitted
      by law. The customer account shall be unblocked after the suspicion has been resolved.
    </div>
  </div>
  <div class="contract-obligation-content__subheading">
    <div class="contract-obligation-content__index">12.</div>
    <div class="contract-obligation-content__text">Liability</div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">12.1</div>
    <div class="contract-obligation-content__text">Kärcher shall be liable without limitation in the event of wilful
      misconduct or gross negligence. In the event of simple negligence, Kärcher shall only be liable upon violation of
      a material contractual obligation by Kärcher or by any of Kärcher's legal representatives, executive employees or
      vicarious agents. In this case, Kärcher's liability shall - in terms of the amount - be limited to the typical
      damage that could be foreseen at the time the contract was entered into. Material contractual obligations are
      obligations whose fulfilment is a prerequisite for the proper performance of the contract and may be relied upon
      by the Customer.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__spacer"></div>
    <div class="contract-obligation-content__text">In all other cases, all liability of Kärcher shall be excluded.</div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">12.2</div>
    <div class="contract-obligation-content__text">Clause 12.1 above shall not affect any mandatory statutory
      liability.
    </div>
  </div>
  <div class="contract-obligation-content__subheading">
    <div class="contract-obligation-content__index">13.</div>
    <div class="contract-obligation-content__text">Term and termination</div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">13.1</div>
    <div class="contract-obligation-content__text">The contract shall take effect upon acceptance of these T&C by the
      Customer and shall be for an indefinite term.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">13.2</div>
    <div class="contract-obligation-content__text">The contract may be terminated by either party with three months'
      notice, with effect at the end of a month.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">13.3</div>
    <div class="contract-obligation-content__text">The above shall not affect the right of either party to terminate the
      contract for cause.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__spacer"></div>
    <div class="contract-obligation-content__text">Kärcher shall, in particular, have a right to terminate the contract
      for cause:
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">13.3.1</div>
    <div class="contract-obligation-content__text">if the Customer violates material terms or conditions of the
      contract, provided that a deadline set by Kärcher for remedial action has expired without remedial action having
      been taken or that a required warning has been given to no avail;
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">13.3.2</div>
    <div class="contract-obligation-content__text">to the extent that Kärcher cannot reasonably be expected to adhere to
      the contractual relationship for any other serious reasons.
    </div>
  </div>
  <div class="contract-obligation-content__subheading">
    <div class="contract-obligation-content__index">14.</div>
    <div class="contract-obligation-content__text">Data protection</div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">14.1</div>
    <div class="contract-obligation-content__text">Both parties shall comply with the applicable statutory provisions
      regarding the protection of personal data, in particular the provisions of the General Data Protection Regulation
      (GDPR).
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">14.2</div>
    <div class="contract-obligation-content__text">The parties are aware that combinations of system key data based on
      the Machine Data provided by Kärcher and additional information entered by the Customer into the customer portal,
      where applicable, may contain personal data of the Customer or the Customer's employees, or of the staff operating
      the machines.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">14.3</div>
    <div class="contract-obligation-content__text">The Customer guarantees that it has the right to lawfully process the
      data in the customer portal, in particular the personal data, if any. The Customer guarantees that the respective
      permissions for the processing of personal data exist and will be maintained.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">14.4</div>
    <div class="contract-obligation-content__text">In particular in an employment context, the Customer shall be solely
      responsible for ensuring that the collection and use of data in the customer portal comply with the applicable
      data protection laws.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">14.5</div>
    <div class="contract-obligation-content__text">The personal data in the customer portal will only be processed by
      Kärcher on behalf of the Customer, in accordance with the attached data processing agreement.
    </div>
  </div>
  <div class="contract-obligation-content__subheading">
    <div class="contract-obligation-content__index">15.</div>
    <div class="contract-obligation-content__text">Confidentiality</div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">15.1</div>
    <div class="contract-obligation-content__text">The parties undertake to maintain the strict and unconditional
      confidentiality of all facts, information and documents, including trade secrets, (in written, electronic,
      embodied or oral form) of which they obtain knowledge in connection with the contract and its performance and
      which are marked as confidential or must be regarded as confidential according to the circumstances (hereinafter,
      "Confidential Information"). The Confidential Information shall, in particular, include the existence of the
      contract and its contents, as well as any digitally embodied information (data), operational procedures and
      know-how of the parties. The Confidential Information to be kept confidential by the Customer shall further
      include, without limitation, the Machine Data that is the sole property of AKW and the rights in respect of which
      are owned exclusively by AKW and its affiliates pursuant to clause 6 of these T&C.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">15.2</div>
    <div class="contract-obligation-content__text">This obligation shall not apply to facts, information and documents
      that:
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">15.2.1</div>
    <div class="contract-obligation-content__text">were demonstrably already known to the parties at the time the
      contract was entered into or are subsequently made known to them by a third party without violation of any
      confidentiality agreement, statutory provisions or official orders;
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">15.2.2</div>
    <div class="contract-obligation-content__text">are publicly known at the time the contract is entered into or are
      made publicly known thereafter, except where this is due to a breach of contract;
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">15.2.3</div>
    <div class="contract-obligation-content__text">must be disclosed due to statutory obligations, or by order of a
      court or public authority. To the extent permitted and feasible, the party required to make a disclosure shall
      notify the respective other party in advance and give it the opportunity to proceed against the disclosure.
      Furthermore, the party shall indicate in the course of disclosure that the relevant information constitutes trade
      secrets, where this is the case, and seek to ensure that the provisions of Sections 16 et seq. German Trade
      Secrets Act (GeschGehG) are made use of;
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">15.2.4</div>
    <div class="contract-obligation-content__text">were developed by an employee of the party without knowledge or use
      of Confidential Information.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">15.3</div>
    <div class="contract-obligation-content__text">The parties shall limit access to Confidential Information to those
      consultants and advisors who are bound to professional secrecy or who have previously assumed obligations that
      substantially correspond to the confidentiality obligations under this contract. Furthermore, the parties shall
      disclose Confidential Information only to those of their own employees and of the employees of any of their
      affiliates (verbundene Unternehmen), as defined in Sections 15 et seq. German Stock Corporation Act (AktG), who
      need to know such information for the performance of the contract and have previously assumed obligations that
      substantially correspond to the confidentiality obligations under this contract, and they shall require these
      employees to maintain confidentiality even after their employment has ended, to the extent permitted under
      employment law.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">15.4</div>
    <div class="contract-obligation-content__text">The parties shall be obliged to comply with the latest state of the
      art as regards data security and the protection of Confidential Information and shall protect such by implementing
      appropriate technical and organisational measures. The parties shall notify each other of any existing security
      gaps, and any threat of security gaps. They shall make sure when selecting and appointing their employees that the
      persons acting from time to time also observe the latest state of the art in the field of data security and have
      in all their activities regard to the need to maintain the confidentiality of the Confidential Information.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">15.5</div>
    <div class="contract-obligation-content__text">The Customer additionally undertakes not to reverse engineer,
      decompile, disassemble or otherwise examine the composition and/or creation of Confidential Information, unless
      this is necessary for the performance of contractual services and Kärcher has given its express prior written
      consent. "Reverse engineering" shall mean all actions, including observing, testing, examining and deconstructing
      and, where applicable, reconstructing, performed with a view to obtaining Confidential Information.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">15.6</div>
    <div class="contract-obligation-content__text">Upon termination of the contract, for whatever reason, all
      Confidential Information that has been obtained by the parties, including all copies made thereof, shall be
      returned or, at the request of the other party, be deleted and/or destroyed. In the event of deletion and/or
      destruction, it must be ensured that the Confidential Information cannot be reconstructed. Upon request, the
      respective party shall confirm in writing that the Confidential Information has been completely returned or
      deleted and/or destroyed. The preceding provisions shall not apply to general correspondence between the parties
      or to Confidential Information whose retention by the parties is required by law or official order.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">15.7</div>
    <div class="contract-obligation-content__text">Any machine data that is the sole property of AKW and the rights in
      respect of which are owned exclusively by AKW pursuant to clause 6 of these T&C shall be exempt from the
      obligations to deliver and delete.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">15.8</div>
    <div class="contract-obligation-content__text">The parties' obligation to maintain confidentiality shall exist for
      the duration of the contract and another five (5) years from the termination of the contract.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">15.9</div>
    <div class="contract-obligation-content__text">Kärcher shall have the right to report on the parties' business
      relationship under the contract in press releases or other publications or use the Customer or the Customer's
      company logo or registered trademarks as a reference.
    </div>
  </div>
  <div class="contract-obligation-content__subheading">
    <div class="contract-obligation-content__index">16.</div>
    <div class="contract-obligation-content__text">Amendments to the General Terms and Conditions of Contract</div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">16.1</div>
    <div class="contract-obligation-content__text">These T&C have been drawn up in view of the factual and legal
      circumstances at the time of conclusion of the contract. In the event that these circumstances change, in
      particular in the event of changes to existing, or the creation of new, mandatory statutory provisions or rulings
      of the highest competent courts (e.g. the German Federal Court of Justice (BGH)), Kärcher shall have the right to
      amend these T&C in line with the changed circumstances. This shall also apply if the performance of the contract
      in accordance with the provisions of these T&C becomes unreasonable as a result of the changed circumstances.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">16.2</div>
    <div class="contract-obligation-content__text">Kärcher shall notify the Customer in text form of the amended T&C,
      highlighting and, where necessary, explaining the amended provisions of the T&C, not less than six (6) weeks
      before the effective date of the amended T&C. The notification shall be made by means of an announcement in the
      customer portal or by e-mail.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">16.3</div>
    <div class="contract-obligation-content__text">The Customer may object to the amended T&C in text form, or terminate
      the contract without notice, prior to the effective date of the amended T&C.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">16.4</div>
    <div class="contract-obligation-content__text">If the Customer does not object to, or terminate, the amended T&C
      within the period of time mentioned in clause 16.2 of these T&C, the amended T&C shall apply upon expiry of said
      period.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">16.5</div>
    <div class="contract-obligation-content__text">If the Customer objects to the amended T&C, Kärcher shall have the
      right to terminate the contract with effect from the announced effective date of the amended T&C.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">16.6</div>
    <div class="contract-obligation-content__text">When notifying the Customer of the amended T&C, Kärcher shall advise
      the Customer of its rights to object and to terminate the contract, as well as of the consequence upon expiry of
      the deadline if the Customer does not object or terminate the contract.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">16.7</div>
    <div class="contract-obligation-content__text">The provisions of clause 13 of these T&C shall not be otherwise
      affected by this clause 16.
    </div>
  </div>
  <div class="contract-obligation-content__subheading">
    <div class="contract-obligation-content__index">17.</div>
    <div class="contract-obligation-content__text">Final provisions</div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">17.1</div>
    <div class="contract-obligation-content__text">The contract shall be governed by, and construed in accordance with,
      the laws of the Federal Republic of Germany, without regard to the United Nations Convention of 11 April 1980 on
      Contracts for the International Sale of Goods (CISG) and without regard to the conflict-of-laws provisions.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">17.2</div>
    <div class="contract-obligation-content__text">The exclusive place of jurisdiction for all disputes arising out of
      or in connection with the contract shall be Stuttgart, Germany, to the extent permitted by law. Kärcher shall,
      however, also have the right to initiate legal proceedings in the courts of competent jurisdiction over the
      Customer's place of business.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">17.3</div>
    <div class="contract-obligation-content__text">The contractual services shall be governed exclusively by these T&C.
      Conflicting or deviating terms and conditions of the Customer shall not apply unless Kärcher expressly approves
      their application in writing. The rule in the first sentence above shall also apply if Kärcher provides
      contractual services without reservations whilst being aware of conflicting or deviating terms and conditions of
      the Customer.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">17.4</div>
    <div class="contract-obligation-content__text">All changes and additions to the contract that do not fall within the
      scope of clause 16 above must be made in writing (Schriftform) or must at least have a simple or advanced
      electronic signature attached to them or logically associated with them. This shall also apply to changes to this
      written form clause. No ancillary agreements have been made.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">17.5</div>
    <div class="contract-obligation-content__text">All notices to be given under the contract must be given in writing
      (Schriftform), except where expressly otherwise agreed by contract.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">17.6</div>
    <div class="contract-obligation-content__text">Should any provision of the contract be or become invalid or
      impracticable, this shall not affect the validity of the remaining provisions of the contract. The invalid or
      impracticable provision shall be replaced or supplemented with a legally valid provision that corresponds to what
      the parties wanted or would have wanted if they had been aware of the invalidity or impracticability. The same
      shall apply to any gaps in the contract.
    </div>
  </div>
  <div class="contract-obligation-content__paragraph">
    <div class="contract-obligation-content__index">17.7</div>
    <div class="contract-obligation-content__text">Kärcher shall have the right to transfer the contract or individual
      rights or obligations under the contract to any of Kärcher's affiliates (verbundene Unternehmen), as defined in
      Sections 15 et seq. German Stock Corporation Act (AktG). In all other cases, transfers shall be subject to written
      approval by the respective other party.
    </div>
  </div>
</div>`,
};
